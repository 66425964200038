<template>
  <div class="system_log">
    <div class="card_box">
      <!-- 标题 -->
      <div class="title_box">
        <div></div>
        <span>日志列表</span>
      </div>

      <!-- 搜索框和表格 -->
      <div class="table_box">
        <div class="selector">
          <el-select
            v-model="id_operator"
            placeholder="全部运营商"
            style="margin-right: 14px"
            class="w200"
            @change="id_project = ''"
            v-supAdmin
          >
            <el-option
              v-for="item in operatorList"
              :key="item.id_operator"
              :label="item.name_operator"
              :value="item.id_operator"
            >
            </el-option>
          </el-select>

          <el-select
            v-model="id_project"
            placeholder="全部项目"
            style="margin-right: 14px"
            class="w200"
          >
            <el-option
              v-for="item in computedProject"
              :key="item.id"
              :label="item.name_project"
              :value="item.id"
            >
            </el-option>
          </el-select>

          <el-select
            v-model="action_type"
            placeholder="全部类型"
            style="margin-right: 14px"
            class="w200"
          >
            <el-option
              v-for="item in actionTypeList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>

          <el-input
            v-model="name"
            placeholder="输入IMEI或设备名称查询"
            class="w200"
            style="margin: 10px 14px 0 0"
          ></el-input>

          <el-date-picker
            v-model="date"
            type="daterange"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            class="w240"
            style="margin: 10px 14px 0 0"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>

          <el-button
            type="success"
            icon="el-icon-search"
            @click="search"
            style="margin: 10px 14px 0 0"
            >查询</el-button
          >
          <el-button
            type="info"
            icon="el-icon-refresh-left"
            @click="reset"
            style="margin: 10px 14px 0 0"
            >重置</el-button
          >
          <el-button
            type="primary"
            icon="el-icon-download"
            @click="export2excel"
            style="margin: 10px 14px 0 0"
            >导出</el-button
          >
        </div>

        <!-- 表格 -->
        <el-table
          :data="logList"
          style="width: 100%"
          border
          :header-cell-style="{
            background: '#fafafb'
          }"
          size="small"
        >
          <el-table-column prop="time_create" label="时间"></el-table-column>
          <el-table-column prop="addr" label="设备名称"></el-table-column>
          <el-table-column
            prop="id_device"
            label="IMEI"
            min-width="90"
          ></el-table-column>
          <el-table-column
            v-if="level === 100"
            prop="name_operator"
            label="运营商"
          ></el-table-column>
          <el-table-column prop="name_project" label="项目"></el-table-column>
          <el-table-column prop="name_building" label="楼栋"></el-table-column>
          <el-table-column label="楼层">
            <template v-slot="scope">
              <span>{{ scope.row.floor }}F</span>
            </template>
          </el-table-column>
          <el-table-column label="类型">
            <template v-slot="scope">
              <span class="primary" v-if="scope.row.action_type === 1"
                >设备启动</span
              >
              <span class="primary" v-else-if="scope.row.action_type === 2"
                >设备关闭</span
              >
              <span class="offline" v-else-if="scope.row.action_type === 3"
                >空调锁定</span
              >
              <span class="offline" v-else-if="scope.row.action_type === 4"
                >空调解锁</span
              >
              <span class="del" v-else-if="scope.row.action_type === 5"
                >删除控制规则</span
              >
              <span class="del" v-else>{{ scope.row.action_type }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作人">
            <template v-slot="scope">
              <span>{{ scope.row.user_name || '--' }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <!-- 分页功能 -->
      <div class="pages">
        <el-pagination
          @size-change="sizeChange"
          @current-change="currentPageChange"
          :current-page="page_id + 1"
          :page-sizes="[20, 50, 100, 200]"
          :page-size="page_num"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>

    <ExportExcel ref="exportExcelRef" />
  </div>
</template>

<script>
import { GetLogListApi } from '@/api'
import operator from '@/mixins/operator.js'
import project from '@/mixins/project.js'
import ExportExcel from '@/components/export_excel/export_excel.vue'
import { actionTypeFmt } from '@/utils/filter.js'

export default {
  name: 'system_log',
  mixins: [operator, project],
  components: { ExportExcel },
  data() {
    return {
      // 权限级别
      level: '',
      // 分页
      page_id: 0,
      page_num: 20,
      total: 0,
      // 运营商id
      id_operator: '',
      // 项目id
      id_project: '',
      // 操作类型
      action_type: '',
      // 操作名称或IMEI
      name: '',
      // 日历
      date: null,
      // 日志列表
      logList: [],
      // 操作类型列表
      actionTypeList: [
        { id: 1, name: '设备启动' },
        { id: 2, name: '设备关闭' },
        { id: 3, name: '空调锁定' },
        { id: 4, name: '空调解锁' },
        { id: 5, name: '删除控制规则' }
      ]
    }
  },
  methods: {
    // 分页尺寸变化
    sizeChange(size) {
      this.page_num = size
      this.getLogList()
    },

    // 当前页变化
    currentPageChange(page) {
      this.page_id = page - 1
      this.getLogList()
    },

    // 获取参数列表
    getParams() {
      const params = {
        page_id: this.page_id,
        page_num: this.page_num
      }
      if (this.id_operator) {
        params.id_operator = this.id_operator
      }
      if (this.id_project) {
        params.id_project = this.id_project
      }
      if (this.action_type) {
        params.action_type = this.action_type
      }
      if (this.name.trim()) {
        params.name = this.name.trim()
      }
      if (this.date) {
        params.time_start = this.date[0] + ' 00:00:00'
        params.time_end = this.date[1] + ' 23:59:59'
      }
      return params
    },

    // 查询
    search() {
      this.page_id = 0
      this.getLogList()
    },

    // 重置
    reset() {
      this.id_operator = ''
      this.id_project = ''
      this.action_type = ''
      this.name = ''
      this.date = null
      this.page_id = 0
      this.getLogList()
    },

    // 获取日志列表
    async getLogList() {
      const params = this.getParams()
      const { ret, data, msg } = await GetLogListApi(params)
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      this.logList = data.data
      this.total = data.cnt_all
    },

    // 导出excel
    export2excel() {
      const params = this.getParams()
      this.$refs.exportExcelRef.export({
        filename: '日志列表',
        func: GetLogListApi,
        params,
        datapath: ['data'],
        countpath: ['cnt_all'],
        kw: {
          time_create: '时间',
          addr: '设备名称',
          id_device: 'IMEI',
          name_operator: '运营商',
          name_project: '项目',
          name_building: '楼栋',
          floor: '楼层',
          action_type: '类型',
          user_name: '操作人'
        },
        fmt: {
          floor(v) {
            return v + ' F'
          },
          action_type: actionTypeFmt
        }
      })
    }
  },
  created() {
    this.level = this.$store.getters.level
    if (this.level === 100) {
      this.getOperatorList()
    }
    this.getProjectList()
    this.getLogList()
  },

  computed: {
    // 计算项目
    computedProject() {
      if (this.id_operator) {
        return this.projectList.filter(i => {
          return i.id_operator === this.id_operator
        })
      } else {
        return this.projectList
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.system_log {
  padding: 0 22px;
}

.card_box {
  background-color: #fff;
  min-height: calc(100vh - 160px);
  margin-top: 24px;
}

.title_box {
  height: 48px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ebedf2;

  div {
    width: 4px;
    height: 18px;
    background: linear-gradient(-75deg, #50e379, #50e398);
    box-shadow: 0px 3px 4px 0px rgba(80, 227, 146, 0.35);
    margin: 0 11px 0 26px;
  }

  span {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #12203e;
  }
}

.table_box {
  padding: 0 22px;

  .selector {
    padding: 10px 0 20px;
  }
}
</style>
